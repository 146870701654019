import React from "react";
import FaceComparison from "../../pages/FaceComparison/FaceComparison";
import { Routes, Route } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import IdOcr from "../../pages/IdOcr/IdOcr";
import LivenessCheck from "../../pages/LivenessCheck/LivenessCheck";
import { Layout } from "./Layout";

const NotFound = () => <div>Notfound</div>;

export const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<FaceComparison />} />
          <Route path="/face-compare" element={<FaceComparison />} />
          <Route path="/id-process" element={<IdOcr />} />
          <Route path="/liveness-check" element={<LivenessCheck />} />
          
        </Route>
        {/* <Route path="*" element={<NotFound/>}/> */}
      </Routes>
    </Router>
  );
};
