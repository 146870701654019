import React from "react";
import styled from "@emotion/styled";

export const PreviewWrapper = styled.section`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const PreviewItem = styled.section`
  padding: 4px;
  /* display: flex; */
  transition: opacity 0.5s ease-in-out;
  background: ${(props) => (props.isActive ? "none" : "#f6f6f6")};
  min-width: ${(props) => (props.isActive ? "0" : "400px")};
  min-height: 235px;

  img {
    width: 100%;
    max-width: 330px;
  }
`;

export const DemoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  align-items: center;
  @media only screen and (min-width: 650px) {
    align-items: initial;
    justify-content: space-evenly;
  }
`;

export const Results = styled.div`
  width: 60%;
  background: #f6f6f6;
  border-radius: 5px;
  justify-content: center;
  color: gainsboro;
  max-width: 450px;
  min-height: 250px;
  overflow: overlay;
 .clip-loader{
  margin-top: 50%;
  }
`;
