import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { useStickyState } from "../../components/Carousel/useStickyState";
import "./LivenessCheck.css";
import { CarouselItem } from "../../components/Carousel/useCarousel";
import { Carousel } from "../../components/Carousel/Carousel";
import { ToastContainer, toast } from "react-toastify";
import { ActiveCarousel } from "../FaceComparison/FaceComparison";
import { load } from "../../components/Carousel/Carousel.utils";
import { UploadButton } from "../../components/UploadButton/UploadButton";
import ReactJson from "react-json-view";
import { AiService, LivenessCheckResult } from "../../services/AiService";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { CaptchaService } from "../../services/CaptchaService";
import { DemoContainer, PreviewItem, PreviewWrapper, Results } from "./LivenessCheck.styles";
import { useService } from "../../components/hooks/useService";
import ClipLoader from "react-spinners/ClipLoader";
import { extractBase64FromImage } from "../../common/Utils";
import { useMediaQuery } from "react-responsive";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AiOutlineClose } from "react-icons/ai";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'

const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "" as string | ArrayBuffer | null;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

const color = "#1d9bf0";

function LivenessCheck() {
  const aiService = new AiService();
  const captchaService = new CaptchaService();
  const { isLoading: isLivenessCheckLoading, execute: checkLiveness } = useService(aiService.checkLiveness);
  const { isLoading: isCaptchaLoading } = useService(captchaService.validateToken);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [livenessCheckResult, setLivenessCheckResult] = useState<LivenessCheckResult>();
  const [carouselItem, setCarouselItem] = useState<CarouselItem<string>>();
  const [uploadedImages, setUploadedImages, clearUploads] = useStickyState<string[]>([], "liveness-check");
  const [mixedImages, setMixedImages] = useState<string[]>(uploadedImages);
  const [toggleResetValue, toggleReset] = useState(false);
  const isSmallScreen = useMediaQuery({maxWidth: 1200});
  useEffect(() => {
    load(ActiveCarousel.LIVENESSCHECK).then((x) => setMixedImages([...uploadedImages, ...x]));
  }, [uploadedImages, toggleResetValue]);

  const [activeCarousel, setActiveCarousel] = useState();
  const handleFileInputChange = async (e) => {
    setLivenessCheckResult(undefined);
    const file = e.target.files[0];
    const base64 = (await getBase64(file)) as string;
    const filesize = base64.length * (3 / 4) - 2;

    if (base64.includes("/bmp") || base64.includes("/jpeg") || base64.includes("/png")) {
      if (filesize > 25000000) {
        toast.error("Your image must be less than 25MB.");
        return;
      } else {
        setUploadedImages([base64, ...uploadedImages]);
      }
    } else {
      toast.warn("Available image file types:  (BMP, JPEG, PNG).");
      return;
    }
  };

  const handleCompare = async () => {
    setLivenessCheckResult(undefined);
    const base64Image = extractBase64FromImage(carouselItem?.data || "");
    const token = await executeRecaptcha!();
    const result = await checkLiveness({ image: base64Image, token: token });

    if (result.success) {
      setLivenessCheckResult(result.value);
      return;
    }

    if (base64Image == "invalidImageType") {
      setLivenessCheckResult(undefined);
      toast.warn("Available image file types:  (BMP, JPEG, PNG).");
      return;
    }
    setLivenessCheckResult(undefined);
    toast.error("The selected file cannot be validated.");
  };

  let itemsToShow = 5;

  const isMobileAndTablet = useMediaQuery({
    minWidth: 375,
    maxWidth: 649,
  });
  const isTabletAndGreater = useMediaQuery({
    query: "(min-width: 649px)",
  });
  if (isMobileAndTablet) {
    itemsToShow = 2;
  }
  if (isTabletAndGreater) {
    itemsToShow = 4;
  }
  return (
    <div className="container">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="liveness-check-page-content mt-4">
        {/*@ts-ignore */}
        <Animated animationIn="zoomIn" animationOut="zoomOut">
          <DemoContainer>
            <div className="liveness-check-content">
              <div className="liveness-check-preview">
                <PreviewWrapper className="preview-content">
                  <PreviewItem isActive={activeCarousel ?? false} onClick={() => setActiveCarousel(activeCarousel)}>
                    <img src={`${carouselItem?.data ?? ""}`} />
                  </PreviewItem>
                </PreviewWrapper>
                {mixedImages && mixedImages.length > 0 && (
                  <Carousel
                    id="carousel-1"
                    data={mixedImages}
                    onChange={(item) => {
                      setCarouselItem(item);
                    }}
                    itemsToShow={itemsToShow}
                    hide={false}
                  />
                )}
                <section className="button-actions">
                  <UploadButton onChange={handleFileInputChange} />
                  <button className="compare-button" onClick={handleCompare} disabled={isLivenessCheckLoading || isCaptchaLoading}>
                    {isLivenessCheckLoading || isCaptchaLoading ? "Loading..." : "Send"}
                  </button>
                  <button
                    type="button"
                    className="compare-button"
                    onClick={() => {
                      clearUploads();
                      toggleReset(!toggleResetValue);
                    }}
                  >
                    Reset
                  </button>
                </section>
              </div>
              {isSmallScreen ? (
                /* @ts-ignore */
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={!!livenessCheckResult}>
                <Popup className="liveness-check-popup" open={!!livenessCheckResult} position="top center">
                  <div className="popup-button">
                    <button type="button" className="btn border-0" onClick={() => setLivenessCheckResult(undefined)}>
                      <AiOutlineClose />
                    </button>
                  </div>
                  <Results className="liveness-check-results">
                    {livenessCheckResult ? (
                      <Animated
                        animationIn="fadeIn"
                        animationOut="fadeOut"
                        // @ts-ignore
                        isVisible={livenessCheckResult!!}>
                        <ReactJson collapsed={1} src={livenessCheckResult} displayDataTypes={false} />
                      </Animated>
                    ) : (
                      <h2 style={{ textAlign: "center", padding: "10px" }}>
                        {isLivenessCheckLoading || isCaptchaLoading ? (

                          <ClipLoader className="clip-loader" size={150} color={color} />
                        ) : (
                          "Please select an image and click Send."
                        )}
                      </h2>
                    )}
                  </Results>
                </Popup>
                </Animated>
              ) : (
                <Results className="liveness-check-results">
                  {livenessCheckResult ? (
                    <Animated
                      animationIn="fadeIn"
                      animationOut="fadeOut"
                      // @ts-ignore
                      isVisible={livenessCheckResult!!}>
                      <ReactJson collapsed={1} src={livenessCheckResult} displayDataTypes={false} />
                    </Animated>
                  ) : (
                    <h2 style={{ textAlign: "center", padding: "10px" }}>
                      {isLivenessCheckLoading || isCaptchaLoading ? (

                        <ClipLoader className="clip-loader" size={150} color={color} />
                      ) : (
                        "Please select an image and click Send."
                      )}
                    </h2>
                  )}
                </Results>
              )}
            </div>
          </DemoContainer>
        </Animated>
      </div>
    </div>
  );
}
export default LivenessCheck;
