export class AiService {
  client: HttpClient;
  backendEndpoint: string | undefined;

  constructor() {
    this.client = new HttpClient();
    this.backendEndpoint = process.env.REACT_APP_KYC_TEST_PAGE_API_END_POINT;
    this.extractOcr = this.extractOcr.bind(this);
    this.compareFaces = this.compareFaces.bind(this);
    this.checkLiveness = this.checkLiveness.bind(this);
  }

  compareFaces(model: RequestPayload<{image1: string; image2: string}>): Promise<Result<ComparisonResult>> {
    const headers = {
      "x-captcha-token": model.token,
    };
    return this.client.post(`${this.backendEndpoint}api/face-compare`, {
      left: model.image1,
      right: model.image2,
    },
    headers
    );
  }

  checkLiveness(model: RequestPayload<{ image: string;  }>): Promise<Result<LivenessCheckResult>> {
    const headers = {
      "x-captcha-token": model.token,
    };
   
    return this.client.post(
      `${this.backendEndpoint}api/liveness-check`, {
      idCard: model.image,
    },
    headers
    );
  }

  extractOcr(model: RequestPayload<{ image: string }>): Promise<Result<any>> {
    const headers = {
      "x-captcha-token": model.token,
    };

    return this.client.post(
      `${this.backendEndpoint}api/id-process`,
      {
        idCard: model.image,
      },
      headers
    );
  }
}

type RequestPayload<T> = {
  [K in keyof T]: T[K];
} & { token: string};

export type ComparisonImages = {
  image1: string;
  image2: string;
};
export type LivenessCheckResult = {
  liveness: boolean;
};

export type ComparisonResult = {
  score: number;
  result: boolean;
};

export class HttpClient {
  async post<T, TResult>(url: string, payload: T, headers?: Record<string, string>): Promise<Result<TResult>> {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
        body: JSON.stringify(payload),
      });

      let data = response.ok ? await response.json() : await response.text();
      return response.ok ? { success: true, value: data } : { success: false, error: data };
    } catch (error) {
      return {
        success: false,
        error: error instanceof Error ? (error as Error).message : "Unexpected network error",
      };
    }
  }
}

//type StatusCodeResult = { StatusCode: number; ErrorId: string; Description: string };

export type Result<T> = { success: true; value: T } | { success: false; error: string };
