import React from "react";
import styled from "@emotion/styled";

export const CarouselWrapper = styled.section`
  display: inline-flex;
  margin-top: 4px;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  display: ${(props) => (props.hide ? "none" : "auto")};
  .button-carousel{
    display: flex;
    align-items: center;
  }
`

export const CarouselButton = styled.button`
    width: 50px;
    margin: 20px;
    height: 50px;
    border-radius: 50px;
    border: 1px solid gainsboro;
    transition: background-color 300ms ease-in-out;
    transition: 300ms;
    color: #b4b4b4;
    background: white;
    font-size: 25px;
    &:hover {
        background: #f6f6f6;
        cursor: pointer;
        border: 1px solid rgb(29, 155, 240) ;
        color: rgb(29, 155, 240);
        
    }
    @media only screen and (max-width: 650px) {
      width: 30px;
    }

`

export const CarouselContainer = styled.section`
  width: 100%;
  background-color: #f6f7fb;
  overflow: hidden;
`;

export const CarouselViewport = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  transform: translateX(-${(props) => props.translate}px);
  transition: transform ease-out ${(props) => props.transition}s;
  height: 100%;
  width: ${(props) => props.width}px;
  display: flex;
`;

export const CarouselElement = styled.li`
  padding: 3px;
  transition: background-color 500ms ease-out;
  border:2px solid ${(props) => (props.isActive ? "#3e98c7;" : "white")};   
  height: 100%;
  flex: 0 0 ${(props) => props.itemWidth}px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  img {
    max-width: 100%;
    margin: 0 auto;
  }
  &:hover{
    transition: 1s;
    z-index:1;
  }
`;
