import { useCallback, useState } from 'react';

function isFunction(value: unknown): value is () => unknown {
  return typeof value === 'function';
}

/**
 * This hook stores values in local or session storage, as well as
 * in react state. This means that if a page is reloaded, the state
 * will be retrieved from the session storage if it exists. If it
 * doesn't exist, or is unparseable, the default value will be used
 * instead.
 *
 * The value must be convertable to JSON.
 *
 * If `session` is true, sessionStorage will be used, otherwise localStorage is used.
 *
 * Do not use this on any pages which will be server-side rendered.
 *
 * Do not use this hook to set the same state in multiple components,
 * as the state is only loaded from storage when the component is initially rendered,
 * it's not synced across the components. It's recommended to use the useStickyState
 * in parent component and pass down the state setter to child components
 */
export function useStickyState<T>(
  defaultValue: T | (() => T),
  key: string,
  session?: boolean
): [T, React.Dispatch<React.SetStateAction<T>>, () => void] {
  const setStorage = useCallback(
    (storageValue: React.SetStateAction<T>) => {
      (session ? window.sessionStorage : window.localStorage).setItem(
        key,
        JSON.stringify(storageValue)
      );
    },
    [key, session]
  );

  const [value, setValue] = useState<T>(() => {
    const stickyValue = (session ? window.sessionStorage : window.localStorage).getItem(key);
    try {
      if (stickyValue === null) {
        if (isFunction(defaultValue)) {
          setStorage(defaultValue());
          return defaultValue();
        }
        setStorage(defaultValue);
        return defaultValue;
      }
      return JSON.parse(stickyValue);
    } catch (e) {
      console.error(e);
      if (isFunction(defaultValue)) {
        setStorage(defaultValue());
        return defaultValue();
      }
      setStorage(defaultValue);
      return defaultValue;
    }
  });

  const setStorageValue = useCallback(
    (newValue: React.SetStateAction<T>) => {
      setValue(newValue);
      setStorage(newValue);
    },
    [setValue, setStorage]
  );

  const clearStorageValue = useCallback(() => {
    (session ? window.sessionStorage : window.localStorage).removeItem(key);
   setStorageValue(defaultValue);
  }, [key, session]);

  return [value, setStorageValue, clearStorageValue];
}
