import React from "react";
import styled from "@emotion/styled";

export const NavBar = styled.nav`
    ul {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        max-width: 300px;
        margin: 0 auto;
        margin-top: 20px;
        list-style: none;
        padding: 0;
    }
    button{
    border: none;
    border-radius: 4px;
    background-color: rgb(29, 155, 240);
    color: hsl(0, 0%, 100%);
    font-size: 14px;
    margin: 1%;
    }
    button:hover{
    cursor: pointer;
    background: white;
    color: rgb(29, 155, 240);
    border: 1px solid rgb(29, 155, 240);
    transition: 600ms ease-in-out;
    }
`
