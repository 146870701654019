import React from "react";
import styled from "@emotion/styled";

export const PreviewWrapper = styled.section`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 300px;
`;

export const PreviewItem = styled.section`
  display: flex;
  transition: opacity 0.5s ease-in-out;
  opacity: ${(props) => (props.isActive ? 1 : 0.3)};
  //background: ${(props) => (props.isActive ? "#3e98c7" : "none")};
  width: 50%;
  height: 245px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  img {
    max-width: 100%;
    border: 4px solid ${(props) => (props.isActive ? "#3e98c7" : "white")};
    z-index: ${(props) => (props.isActive ? 1 : 0)};
  }
`;

export const DemoContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 1080px) {
    flex-direction: row;
  }
`;

export const Results = styled.div`
  background: #f6f6f6;
  width: 100%;
  border-radius: 5px;
  max-width: 450px;
  min-height: 449px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: gainsboro;
  padding: 5%;
  margin-left: 5%;
`;
