import { HttpClient, Result } from "./AiService";

export class CaptchaService {
  client: HttpClient;
  backendEndpoint: string | undefined;

  constructor() {
    this.client = new HttpClient();
    this.backendEndpoint = process.env.REACT_APP_KYC_TEST_PAGE_API_END_POINT;
    this.validateToken = this.validateToken.bind(this);
  }

  async validateToken(token: string): Promise<Result<ValidateTokenResult>> {
    const result = await this.client.post<string, ValidateTokenResult>(`${this.backendEndpoint}api/captcha`, token);

    if (result.success && result.value.score <= 0.5) {
      result.value.success = false;
    }

    return result;
  }
}

export type ValidateTokenResult = {
  success: boolean;
  score: number;
  hostname: string;
};
