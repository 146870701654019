import { useState } from "react";
import { Animated } from "react-animated-css";
import { useStickyState } from "../../components/Carousel/useStickyState";
import { DemoContainer, PreviewItem, PreviewWrapper, Results } from "./IdOcr.styles";
import { ToastContainer, toast } from "react-toastify";
import "./IdOcr.css";
import ReactJson from "react-json-view";
import { UploadButton } from "../../components/UploadButton/UploadButton";
import { AiService } from "../../services/AiService";
import { CaptchaService } from "../../services/CaptchaService";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useService } from "../../components/hooks/useService";
import ClipLoader from "react-spinners/ClipLoader";
import { extractBase64FromImage } from "../../common/Utils";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useMediaQuery } from "@mui/material";
import { AiOutlineClose } from "react-icons/ai";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "" as string | ArrayBuffer | null;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};
const color = "#1d9bf0";
function IdOcr() {
  const aiService = new AiService();
  const captchaService = new CaptchaService();
  const { isLoading: isOcrLoading, execute: extractOcr } = useService(aiService.extractOcr);
  const { isLoading: isCaptchaLoading } = useService(captchaService.validateToken);

  const { executeRecaptcha } = useGoogleReCaptcha();
  const [fileSizeMessageValidation, setFileSizeMessageValidation] = useState<string>();
  const [isErrorFileValidation, setIsErrorFileValidation] = useState<boolean>();
  const [ocrResult, setOcrResult] = useState();
  const [image, setImage] = useStickyState<string>("", "id-ocr");
  const isSmallScreen = useMediaQuery("(max-width: 750px)");

  const notify = (msg?: string | undefined) => toast(msg ?? fileSizeMessageValidation);
  const handleFileInputChange = async (e) => {
    setOcrResult(undefined);
    const file = e.target.files[0];

    const base64 = (await getBase64(file)) as string;

    const filesize = base64.length * (3 / 4) - 2;

    if (base64.includes("/bmp") || base64.includes("/jpeg") || base64.includes("/png")) {
      if (filesize > 25000000) {
        setFileSizeMessageValidation("Your image must be less than 25MB.");
        setIsErrorFileValidation(true);
        toast.error("Your image must be less than 25MB.");
        return;
      }
      setImage(base64);
    } else {
      toast.warn("Available image file types:  (BMP, JPEG, PNG).");
      return;
    }
  };

  const handleClick = async () => {
    setOcrResult(undefined);
    const imgBase64 = extractBase64FromImage(image || "");
    if (isErrorFileValidation || !executeRecaptcha) {
      notify();
      return;
    }
    const token = await executeRecaptcha();

    const result = await extractOcr({ image: imgBase64, token: token });
    if (result.success) {
      setOcrResult(result.value);
      return;
    }
    if (imgBase64 == "invalidImageType") {
      setOcrResult(undefined);
      toast.warn("Available image file types:  (BMP, JPEG, PNG).");
      return;
    }
    setOcrResult(undefined);
    toast.error("The selected file cannot be validated.");
  };
  return (
    <div className="container">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover={false}
      />
      <div className="id-ocr-page-content">
        {/*@ts-ignore */}
        <Animated animationIn="zoomIn" animationOut="zoomOut">
          <DemoContainer className="demo-container">
            <div className="id-process-content">
              <div className="id-process-preview">
                <PreviewWrapper>
                  {/* @ts-ignore */}
                  <PreviewItem isActive={!!image}>
                    <img src={`${image ?? ""}`} />
                  </PreviewItem>
                </PreviewWrapper>
                <section className="button-actions w-100">
                  <div className="buttons">                 
                  <UploadButton onChange={handleFileInputChange} />
                  <button className="compare-button" onClick={handleClick} disabled={isOcrLoading || isCaptchaLoading}>
                    {isOcrLoading || isCaptchaLoading ? "Loading..." : "Send"}
                  </button>
                  </div>
                </section>
              </div>
              {isSmallScreen ? (
                /* @ts-ignore */
                <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={!!ocrResult}>
                <Popup className="id-ocr-popup" open={!!ocrResult} position="top center">
                  <div className="popup-button">
                    <button type="button" className="btn border-0" onClick={() => setOcrResult(undefined)}>
                    <AiOutlineClose/>
                    </button>
                  </div>
                  <Results className="id-process-results">
                    {ocrResult ? (
                      // @ts-ignore
                      <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={ocrResult!!}>
                        <ReactJson groupArraysAfterLength={1} collapsed={1} src={ocrResult} displayDataTypes={false} />
                      </Animated>
                    ) : (
                      <h2 style={{ textAlign: "center", padding: "10px" }}>
                        {isOcrLoading || isCaptchaLoading ? (
                          <ClipLoader className="clip-loader" size={150} color={color} />
                        ) : (
                          "Please select an image and click Send."
                        )}
                      </h2>
                    )}
                  </Results>
                </Popup>
                </Animated>
              ) : (
                <Results className="id-process-results">
                  {ocrResult ? (
                      /*@ts-ignore */
                    <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={ocrResult!!}>
                      <ReactJson collapsed={1} src={ocrResult} displayDataTypes={false} />
                    </Animated>
                  ) : (
                    <h2 style={{ textAlign: "center", padding: "10px" }}>
                      {isOcrLoading || isCaptchaLoading ? (
                        <ClipLoader className="clip-loader" size={150} color={color} />
                      ) : (
                        "Please select an image and click Send."
                      )}
                    </h2>
                  )}
                </Results>
              )}
            </div>
          </DemoContainer>
        </Animated>
      </div>
    </div>
  );
}
export default IdOcr;
