import React from "react";
import UploadIcon from "@mui/icons-material/Upload";
import "./UploadButton.css";

export type UploadButtonProps = {
  onChange: (e) => void;
};
export const UploadButton: React.FC<React.PropsWithChildren<UploadButtonProps>> = (props) => {
  return (
    <div className="file">
      <label htmlFor="input-file">
        <i className="material-icons">
          <UploadIcon />
        </i>
        {props.children ?? "Select a file"}
      </label>
      <input id="input-file" type="file" onChange={props.onChange} />
    </div>
  );
};
