import React, { useCallback, useEffect, useState } from "react";

export type CarouselItem<TItem> = {
  data: TItem;
  isActive: boolean;
  index: number;
  isFirst: boolean;
  isLast: boolean;
  setActive: () => void;
};

export type Carousel<TItem> = {
  goPrevious: () => void;
  goNext: () => void;
  go: (index: number) => void;
  currentItem: CarouselItem<TItem>;
  items: CarouselItem<TItem>[];
};

export type CarouselProps<TItem> = {
  initialIndex?: number;
  infiniteScroll?: boolean;
  data: TItem[];
};

export function useCarousel<TItem>({
  initialIndex = 0,
  data,
  infiniteScroll = false,
}: CarouselProps<TItem>): Carousel<TItem> {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const firstIndex = 0;
  const lastIndex = data.length - 1;
  const isLastIndex = currentIndex === lastIndex;
  const isFirstIndex = currentIndex === firstIndex;

  const nextIndex = infiniteScroll
    ? isLastIndex
      ? firstIndex
      : currentIndex + 1
    : isLastIndex
    ? lastIndex
    : currentIndex + 1;

  const prevIndex = infiniteScroll
    ? isFirstIndex
      ? lastIndex
      : currentIndex - 1
    : isFirstIndex
    ? firstIndex
    : currentIndex - 1;

  const goPrevious = () => setCurrentIndex(prevIndex);
  const goNext = () => setCurrentIndex(nextIndex);
  const go = (index: number) => setCurrentIndex(index);

  const createItems = useCallback(
    (): CarouselItem<TItem>[] =>
      data.map((entry, i) => {
        return {
          data: entry,
          isActive: i === currentIndex,
          isFirst: i === firstIndex,
          isLast: i === lastIndex,
          index: i,
          setActive: () => setCurrentIndex(i),
        } as unknown as CarouselItem<TItem>;
      }),
    [data, currentIndex, firstIndex, lastIndex]
  );
  const [items, setItems] = useState<CarouselItem<TItem>[]>(createItems());
  useEffect(() => setItems(createItems()), [data, currentIndex]);

  const currentItem = items[currentIndex];

  return {
    goPrevious,
    goNext,
    go,
    currentItem,
    items,
  };
}
