import React from "react";
import styled from "@emotion/styled";

export const PreviewWrapper = styled.section`
width: 50%;
height: 305px;
margin: 0 auto;
display:flex;
justify-content: center;
align-items: center;
`;

export type PreviewItemProps = {
  isActive: Boolean;
};

export const PreviewItem = styled.section<PreviewItemProps>`
  display: flex;
  transition: opacity 0.5s ease-in-out;
  background: ${(props) => (props.isActive ? "#3e98c7" : "none")};
  img {
    max-width: 100%;
    max-height: 290px
  }
`;

export const DemoContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 1080px) {
    flex-direction: column;
  }
`;

export const Results = styled.div`
  width: 100%;
  padding: 50px;
  background: #f6f6f6;
  border-radius: 5px;
  max-height: 478px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gainsboro;
  h2{
    text-align: center;
  }
  @media only screen and (max-width: 1080px) {
    margin: 0 auto;
    padding: 0;
  }
`;
