import React from "react";
import { Animated } from "react-animated-css";
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Outlet } from "react-router-dom";
import { NavBar } from "./App.styles";

export const Layout = () => {
  //const { executeRecaptcha } = useGoogleReCaptcha();
  // const handleVerify = React.useCallback((token: string): void => {}, []);
  return (
    <section style={{ maxWidth: "1366px", margin: "0 auto" }}>
      <NavBar></NavBar>
      <main className="main">
        {/* <GoogleReCaptcha onVerify={handleVerify} /> */}
        <Outlet />
      </main>
      <footer></footer>
    </section>
  );
};
