import { ActiveCarousel } from "./../../pages/FaceComparison/FaceComparison";
import { LivenessCheckResult } from "./../../services/AiService";

const leftCache = {};
const rightCache = {};
const livenessCheckCache = {};

const importAll = (r, cache) => r.keys().forEach((key) => (cache[key] = r(key)));
const fetchAsBase64 = async (url) => {
  return await fetch(url)
    .then((x) => x.arrayBuffer())
    .then((x) => arrayBufferToBase64(x));
};
// @ts-ignore
importAll(require.context("./images/left", false, /\.(png|jpe?g|svg)$/), leftCache);
// @ts-ignore
importAll(require.context("./images/right", false, /\.(png|jpe?g|svg)$/), rightCache);
// @ts-ignore
importAll(require.context("./images/livenessCheck", false, /\.(png|jpe?g|svg)$/), livenessCheckCache);

const leftImages = Promise.all(
  Object.entries(leftCache).map(([key, value]) =>
    fetchAsBase64(value).then((base64) => `data:image/png;base64, ${base64}`)
  )
);
const rightImages = Promise.all(
  Object.entries(rightCache).map(([key, value]) =>
    fetchAsBase64(value).then((base64) => `data:image/png;base64, ${base64}`)
  )
);
const livenessCheckImages = Promise.all(
  Object.entries(livenessCheckCache).map(([key, value]) =>
    fetchAsBase64(value).then((base64) => `data:image/png;base64, ${base64}`)
  )
);

function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export const load = (activeCarousel: ActiveCarousel) => {
  if (activeCarousel == ActiveCarousel.LIVENESSCHECK) {
    return livenessCheckImages;
  } else {
    const images = activeCarousel == ActiveCarousel.LEFT ? leftImages : rightImages;
    return images;
  }
};
