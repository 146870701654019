import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Animated } from "react-animated-css";
import "react-circular-progressbar/dist/styles.css";
import "./FaceComparison.css";
import { Carousel } from "../../components/Carousel/Carousel";
import { CarouselItem } from "../../components/Carousel/useCarousel";
import { DemoContainer, PreviewItem, PreviewWrapper, Results } from "./FaceComparison.styles";
import { useStickyState } from "../../components/Carousel/useStickyState";
import { load } from "../../components/Carousel/Carousel.utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UploadButton } from "../../components/UploadButton/UploadButton";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { AiService, ComparisonResult } from "../../services/AiService";
import { CaptchaService } from "../../services/CaptchaService";
import { useMediaQuery } from "react-responsive";
import ClipLoader from "react-spinners/ClipLoader";
import { useService } from "../../components/hooks/useService";
import { extractBase64FromImage } from "../../common/Utils";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { AiOutlineClose } from "react-icons/ai";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
export enum ActiveCarousel {
  LEFT,
  RIGHT,
  LIVENESSCHECK
}

const getBase64 = (file) => {
  return new Promise((resolve) => {
    let baseURL = "" as string | ArrayBuffer | null;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};
const color = "#1d9bf0";
function FaceComparison() {
  const aiService = new AiService();
  const captchaService = new CaptchaService();
  let { isLoading: isFaceCompareLoading, execute: compareFaces } = useService(aiService.compareFaces);
  const { isLoading: isCaptchaLoading} = useService(captchaService.validateToken);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [comparisonResult, setComparisonResult] = useState<ComparisonResult>();
  const [uploadedImagesLeft, setLeftImages, clearLeft] = useStickyState<string[]>([], "carousel-left");
  const [uploadedImagesRight, setRightImages, clearRight] = useStickyState<string[]>([], "carousel-right");
  const [mixedImagesLeft, setMixedImagesLeft] = useState<string[]>(uploadedImagesLeft);
  const [mixedImagesRight, setMixedImagesRight] = useState<string[]>(uploadedImagesRight);
  const [toggleResetValue, toggleReset] = useState(false);
  const isSmallScreen = useMediaQuery({maxWidth: 1000});
  useEffect(() => {
    load(ActiveCarousel.LEFT).then((x) => setMixedImagesLeft([...uploadedImagesLeft, ...x]));
  }, [uploadedImagesLeft, toggleResetValue]);
  useEffect(() => {
    load(ActiveCarousel.RIGHT).then((x) => setMixedImagesRight([...uploadedImagesRight, ...x]));
  }, [uploadedImagesRight, toggleResetValue]);
  const [carouselLeftItem, setCarouselLeftItem] = useState<CarouselItem<string>>();
  const [carouselRightItem, setCarouselRightItem] = useState<CarouselItem<string>>();
  const [activeCarousel, setActiveCarousel] = useState<ActiveCarousel>(ActiveCarousel.LEFT);
  const handleFileInputChange = async (e) => {
    const file = e.target.files[0];
    const base64 = (await getBase64(file)) as string;
    const filesize = base64.length * (3 / 4) - 2;

    if (base64.includes("/bmp") || base64.includes("/jpeg") || base64.includes("/png")) {
      if (filesize > 25000000) {;
        toast.error("Your image must be less than 25MB.");
        return;
      } 
      activeCarousel == ActiveCarousel.LEFT
        ? setLeftImages([base64, ...uploadedImagesLeft])
        : setRightImages([base64, ...uploadedImagesRight]);
    }
    else{
      toast.warn("Available image file types:  (BMP, JPEG, PNG).");
      return;
    }   
  }
  const handleCompare = async () => {
    setComparisonResult(undefined); 
    const token = await executeRecaptcha!();
    const leftBase64 = extractBase64FromImage(carouselLeftItem?.data || "");
    const rightBase64 = extractBase64FromImage(carouselRightItem?.data || "");
    const result = await compareFaces({
      image1: leftBase64,
      image2: rightBase64,
      token: token
    });

    if (result.success) {
      if ((result.value.score * 100).toFixed(2) < "0") {
        result.value.score = 0;
      }
      setComparisonResult({
        score: parseFloat((result.value.score * 100).toFixed(2)),
        result: result.value.result,
      });
      return;
    } 
      if (leftBase64 == "invalidImageType" || rightBase64 == "invalidImageType") {
      toast.warn("Available image file types:  (BMP, JPEG, PNG).");
      return;
      } 
      toast.error("The selected file cannot be validated.");

  };

  let itemsToShow = 5;

  const isMobileAndTablet = useMediaQuery({
    minWidth: 375,
    maxWidth: 649,
  });
  const isTabletAndGreater = useMediaQuery({
    query: "(min-width: 650px)",
  });
  if (isMobileAndTablet) {
    itemsToShow = 1;
  }
  if (isTabletAndGreater) {
    itemsToShow = 5;
  }

  const submit = () => {};
  return (
    <div className="container">

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
        />

      <div className="face-compare-page-content">
        {/*@ts-ignore */}
        <Animated animationIn="zoomIn" animationOut="zoomOut">
          <DemoContainer>
          <div className="face-compare-content">
            <div className="face-compare-preview">
              <PreviewWrapper className="preview-content">
                <PreviewItem className="preview-img d-flex align-items-end"
                  /*@ts-ignore */
                  isActive={activeCarousel == ActiveCarousel.LEFT}
                  onClick={() => setActiveCarousel(ActiveCarousel.LEFT)}
                >
                  <img src={`${carouselLeftItem?.data ?? ""}`} />
                </PreviewItem>

                <PreviewItem className="preview-img d-flex align-items-start"
                  /*@ts-ignore */
                  isActive={activeCarousel == ActiveCarousel.RIGHT}
                  onClick={() => setActiveCarousel(ActiveCarousel.RIGHT)}
                >
                  <img src={`${carouselRightItem?.data ?? ""}`} />
                </PreviewItem>
              </PreviewWrapper>
              {mixedImagesLeft && mixedImagesLeft.length > 0 && (
                <Carousel
                  id="carousel-1"
                  data={mixedImagesLeft}
                  onChange={(item) => {
                    setCarouselLeftItem(item);
                  }}
                  hide={activeCarousel == ActiveCarousel.RIGHT}
                  itemsToShow={itemsToShow}
                />
              )}
              {mixedImagesRight && mixedImagesRight.length > 0 && (
                <Carousel
                  id="carousel-2"
                  data={mixedImagesRight}
                  onChange={(item) => {
                    setCarouselRightItem(item);
                  }}
                  hide={activeCarousel == ActiveCarousel.LEFT}
                  itemsToShow={itemsToShow}
                />
              )}
              <section className="button-actions">
                <UploadButton onChange={handleFileInputChange} />
                <button className="compare-button" onClick={handleCompare} onSubmit={submit}>
                  {isFaceCompareLoading || isCaptchaLoading ? "Loading..." : "Compare"}
                </button>
                <button
                  type="button"
                  className="compare-button"
                  onClick={() => {
                    clearLeft();
                    clearRight();
                    toggleReset(!toggleResetValue);
                  }}
                >
                  Reset
                </button>
              </section>
            </div>
            {isSmallScreen ? (
              /* @ts-ignore */
              <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={!!comparisonResult}>
             <Popup className="face-compare-popup" open={!!comparisonResult} position="top center">
                  <div className="popup-button">
                    <button type="button" className="btn border-0" onClick={() => setComparisonResult(undefined)}>
                      <AiOutlineClose />
                    </button>
                  </div>
            <Results className="face-compare-results">
              {comparisonResult ? (
                <div>
                  {/* @ts-ignore */}
                  <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={!!comparisonResult}>
                    <h2
                      style={{
                        color: comparisonResult?.result ? "#3e98c7" : "red",
                      }}
                    >
                      Matched: {comparisonResult?.result.toString()}
                    </h2>
                    <CircularProgressbar
                      styles={buildStyles({
                        pathColor: comparisonResult?.result ? "#3e98c7" : "red",
                        textColor: comparisonResult?.result ? "#3e98c7" : "red",
                      })}
                      value={comparisonResult?.score}
                      text={`${comparisonResult?.score}%`}
                    />
                  </Animated>
                </div>
              ) : (
                <h2 style={{ textAlign: "center" }}>
                  {isFaceCompareLoading || isCaptchaLoading ? (
                    <ClipLoader className="clip-loader" size={150} color={color} />
                  ) : (
                    "Please select an image and click Send."
                  )}
                </h2>
              )}
            </Results>
            </Popup>
            </Animated>
            ) : (
              <div className=""> 
              <Results className="face-compare-results">
              {comparisonResult ? (
                <div>
                  {/* @ts-ignore */}
                  <Animated animationIn="fadeIn" animationOut="fadeOut" isVisible={!!comparisonResult}>
                    <h2
                      style={{
                        color: comparisonResult?.result ? "#3e98c7" : "red",
                      }}
                    >
                      Matched: {comparisonResult?.result.toString()}
                    </h2>
                    <CircularProgressbar
                      styles={buildStyles({
                        pathColor: comparisonResult?.result ? "#3e98c7" : "red",
                        textColor: comparisonResult?.result ? "#3e98c7" : "red",
                      })}
                      value={comparisonResult?.score}
                      text={`${comparisonResult?.score}%`}
                    />
                  </Animated>
                </div>
              ) : (
                <h2 style={{ textAlign: "center" }}>
                  {isFaceCompareLoading || isCaptchaLoading ? (
                    <ClipLoader className="clip-loader" size={150} color={color} />
                  ) : (
                    "Please select an image and click Send."
                  )}
                </h2>
              )}
            </Results>
            </div>
            )}
            </div>
          </DemoContainer>
        </Animated>
      </div>
    </div>
  );
}

export default FaceComparison;
