import { height } from "@mui/system";
import { useEffect, useState } from "react";
import {
  CarouselViewport,
  CarouselElement,
  CarouselContainer,
  CarouselButton,
  CarouselWrapper,
} from "./Carousel.styles";
import { CarouselItem, useCarousel } from "./useCarousel";
import { useViewport } from "./useViewport";

type CarouselProps<TItem> = {
  data: TItem[];
  id: string;
  hide: boolean;
  onChange: (item: CarouselItem<TItem>) => void;
  itemsToShow: number;
};

export const Carousel: React.FC<CarouselProps<any>> = ({ data, id, hide, onChange, itemsToShow }) => {
  const viewportWidth = 120 * itemsToShow;
  const { goPrevious, goNext, go, currentItem, items } = useCarousel({
    data,
    infiniteScroll: true,
    initialIndex: 0,
  });
  const { translateX, itemWidth } = useViewport({
    width: viewportWidth,
    itemsToShow: itemsToShow,
    currentIndex: currentItem.index,
  });

  useEffect(() => {
    onChange(currentItem);
  }, [data, currentItem, currentItem.index]);

  return (
    <>
      {/*@ts-ignore */}
      <CarouselWrapper className="buttons" hide={hide}>
        <div className="button-carousel">
          <CarouselButton onClick={() => goPrevious()}>‹</CarouselButton>
        </div>
        <CarouselContainer className="carousel-container" style={{ width: viewportWidth, height:"100px"}}>
          <CarouselViewport
            /*@ts-ignore */
            translate={translateX}
            transition={0.8}
            width={viewportWidth}
          >
            {items.map((item, i) => {
              const { isActive, setActive } = item;
              return (
                <CarouselElement
                  key={i}
                  /*@ts-ignore */
                  isActive={isActive}
                  itemWidth={itemWidth}
                  onClick={setActive}
                >
                  <img src={`${item.data}`} style={{ maxHeight: "85px", margin: "0px auto", position: "absolute", top:"50%", left:"50%", transform: "translate(-50%, -50%)" }} />
                </CarouselElement>
              );
            })}
          </CarouselViewport>
        </CarouselContainer>
        <div className="button-carousel">
          <CarouselButton onClick={() => goNext()}>›</CarouselButton>
        </div>
      </CarouselWrapper>
    </>
  );
};
