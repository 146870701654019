import { useState } from "react";

export const useService = <TArgs, TResult>(func: (args: TArgs) => Promise<TResult>) => {
  const [isLoading, setLoading] = useState<boolean>(false);

  const execute = async (args: TArgs) => {
    setLoading(true);
    const response = await func(args);
    setLoading(false);
    return response;
  };

  return {
    isLoading,
    execute,
  };
};
