import { useEffect, useState } from "react";

type ViewportProps = {
  width: number;
  itemsToShow: number;
  currentIndex: number;
};

export const useViewport = ({
  width,
  itemsToShow,
  currentIndex
}: ViewportProps) => {
  const itemWidth = width / itemsToShow;
  const [state, setState] = useState({
    translateX: 0,
    viewportStart: 0,
    viewportEnd: 0 + width,
    viewportStartIndex: 0,
    viewportEndIndex: itemsToShow - 1
  });

  useEffect(() => {
    const viewportStart = state.translateX;
    const viewportEnd = state.translateX + width;
    const viewportStartIndex = viewportStart / itemWidth;
    const viewportEndIndex = viewportEnd / itemWidth - 1;

    if (currentIndex > viewportEndIndex) {
      setState({
        viewportStart,
        viewportEnd,
        viewportStartIndex,
        viewportEndIndex,
        translateX: viewportStart + itemWidth
      });
    }
    if (currentIndex < viewportStartIndex) {
      setState({
        viewportStart,
        viewportEnd,
        viewportStartIndex,
        viewportEndIndex,
        translateX: viewportStart - itemWidth
      });
    }
  }, [state.translateX, currentIndex, itemWidth, width]);

  return {
    ...state,
    itemWidth
  };
};
